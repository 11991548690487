import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import TimeScreeningse from '@/components/timeScreeningse';
import { GetUserList, DelUserList, Frozen, Thar, AddUserList, UpdataUserList, GetoneUserList } from '@/api/updateUserInfo/Usermanagement';
import { getProfessionalLevelList } from '@/api/personalAnalysis';
import { getGroupList, getStructureListArr } from '@/api/PublicAPI';
import DisplaySettings from '@/components/DisplaySettings/index';
export default {
  data: function data() {
    return {
      // 系统教程
      course: this.$route.query.course,
      prohibit: false,
      tab: 0,
      // 系统教程
      tempId: '',
      value1: '',
      key_time: '',
      title: '人员管理',
      c_time: '',
      //时间戳
      key_word: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      group_id: '',
      structure_id: '',
      s_time: '',
      e_time: '',
      list: [],
      deltableoptions: [],
      //显示设置
      //部门名称
      bumen_id: '',
      bumenoptions: [],
      //角色
      jiaose: '',
      jiaoseoptions: [],
      //新增编辑
      dialogStatus: '',
      textMap: {
        update: '编辑人员',
        create: '新增人员'
      },
      //编辑新增弹窗
      dialogFormVisible: false,
      // 新增编辑界面数据
      end: '',
      editForm: {
        id: '',
        username: '',
        realname: '',
        flower_name: '',
        //花名
        mobile: '',
        group_id: '',
        //所属组id（角色id）
        structure_id: '',
        //部门id
        hiredate: '',
        //入职日期
        positive_date: '',
        //转正日期
        positive_status: '',
        //转正状态
        password: '',
        individual_sales: '',
        group_sales: '',
        is_leader: '',
        email: '',
        professional_level: '' //人员职级
      },
      is_leader: '暂无',
      //是否是主管
      // 人员职级列表
      levelOptions: [],
      editFormRules: {
        username: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        realname: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        flower_name: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        //花名
        mobile: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        group_id: [{
          required: true,
          message: '必选',
          trigger: 'change'
        }],
        //所属组id（角色id）
        structure_id: [{
          required: true,
          message: '必选',
          trigger: 'change'
        }],
        //部门id
        positive_status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }],
        //转正状态
        email: [{
          required: true,
          message: '必选',
          trigger: 'blur'
        }],
        individual_sales: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        group_sales: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        // professional_level: [{ required: true, message: '必选', trigger: 'change' }], //人员职级
        hiredate: [{
          required: true,
          message: '必选',
          trigger: 'blur'
        }]
      }
    };
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'info'
      };
      return statusMap[status];
    }
  },
  components: {
    DisplaySettings: DisplaySettings,
    Head: Head,
    TimeScreeningse: TimeScreeningse
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList();
      this.getALlGroup();
    } else {}
  },
  methods: {
    level: function level(val) {
      var _this2 = this;
      // console.log('val---', val);
      if (val !== '') {
        var leader = this.levelOptions.filter(function (value, index) {
          if (value.professional_level == val) {
            if (value.is_leader == 1) {
              _this2.editForm.group_sales = Number(value.group_sales) / 10000;
            }
            _this2.editForm.individual_sales = Number(value.individual_sales) / 10000;
            return value;
          }
        });
        this.is_leader = leader[0].is_leader;
      } else {
        this.is_leader = '暂无';
      }
    },
    //获取列表
    GetList: function GetList() {
      var _this3 = this;
      GetUserList({
        page: this.query.page,
        pagesize: this.query.pagesize,
        key_word: this.key_word,
        group_id: this.group_id,
        structure_id: this.structure_id,
        s_time: this.s_time,
        e_time: this.e_time
      }).then(function (respomse) {
        _this3.list = respomse.data.user_list;
        _this3.query.total = respomse.data.num;
      });
    },
    getALlGroup: function getALlGroup() {
      var _this4 = this;
      //角色
      getGroupList().then(function (respomse) {
        _this4.jiaoseoptions = respomse.data;
      });
      //部门
      getStructureListArr().then(function (respomse) {
        _this4.bumenoptions = respomse.data;
      });
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this5.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this6.GetList();
      });
    },
    //查询
    handleFilter: function handleFilter() {
      var _this7 = this;
      this.$nextTick(function () {
        _this7.GetList();
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this8 = this;
      if (this.value1 !== null) {
        var s_time = this.value1[0].toString();
        this.s_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.e_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this8.GetList();
        });
      } else {
        this.s_time = '';
        this.e_time = '';
        this.$nextTick(function () {
          _this8.GetList();
        });
      }
    },
    // screening(item) {
    //     this.s_time = item[0];
    //     this.e_time = item[1];
    //     this.query.page = 1;
    //     this.$nextTick(() => {
    //         this.GetList(this.query.page);
    //     });
    // },
    //部门名称
    handleChange: function handleChange(value) {
      var _this9 = this;
      if (value.length !== 0) {
        var lastElement = value[value.length - 1].toString();
        this.structure_id = lastElement;
        this.$nextTick(function () {
          _this9.GetList();
        });
      } else {
        this.structure_id = '';
        this.$nextTick(function () {
          _this9.GetList();
        });
      }
    },
    // 角色名称
    jiaosehandleChange: function jiaosehandleChange(value) {
      var _this10 = this;
      this.group_id = value;
      this.$nextTick(function () {
        _this10.GetList();
      });
    },
    // 显示编辑界面
    handleEdit: function handleEdit() {
      var _this11 = this;
      if (this.deltableoptions.length !== 0) {
        // 获取职级列表
        this.getLevelListFun();
        this.dialogStatus = 'update';
        this.dialogFormVisible = true;
        this.$nextTick(function () {
          _this11.$refs.editForm.clearValidate();
        });
        GetoneUserList({
          id: this.tempId
        }).then(function (respomse) {
          console.log('---', respomse.data);
          // this.editForm.is_leader = respomse.data
          _this11.editForm = Object.assign({}, respomse.data);
          console.log('this.editForm---', _this11.editForm);
          _this11.is_leader = respomse.data.is_leader;
          _this11.editForm.individual_sales = respomse.data.individual_sales / 10000;
          _this11.editForm.group_sales = respomse.data.group_sales / 10000;
          _this11.editForm.group_id = _this11.editForm.group_id.toString();
          _this11.$refs.cascader.$refs.panel.activePath = [];
          _this11.$refs.cascader.$refs.panel.calculateCheckedNodePaths();
          _this11.editForm.structure_id = _this11.editForm.structure_id.map(function (e) {
            var _this11$getDepartment = _this11.getDepartmentNameById(e, _this11.bumenoptions),
              id = _this11$getDepartment.id;
            return id;
          }); //部门id
          _this11.editForm.structure_id = _this11.editForm.structure_id.pop();
        });
      } else {
        this.$message('请一个进行编辑');
      }
    },
    // 显示新增界面
    handleAdd: function handleAdd() {
      var _this12 = this;
      Object.keys(this.editForm).forEach(function (key) {
        return _this12.editForm[key] = '';
      });
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      // 获取职级列表
      this.getLevelListFun();
      this.$nextTick(function () {
        _this12.$refs.editForm.clearValidate();
      });
    },
    // 编辑
    updateData: function updateData() {
      var _this13 = this;
      var end = [this.editForm.structure_id].map(function (e) {
        return e[e.length - 1];
      }).toString();
      var _this = this.editForm;
      UpdataUserList({
        id: _this.id,
        username: _this.username,
        realname: _this.realname,
        flower_name: _this.flower_name,
        //花名
        mobile: _this.mobile,
        group_id: _this.group_id,
        //所属组id（角色id）
        structure_id: end,
        //部门id
        hiredate: _this.hiredate,
        //入职日期
        positive_date: _this.positive_date,
        //转正日期
        positive_status: _this.positive_status,
        //转正状态
        password: _this.password,
        individual_sales: this.is_leader !== '暂无' ? _this.individual_sales * 10000 : 0,
        group_sales: this.is_leader == 1 ? _this.group_sales * 10000 : 0,
        email: _this.email,
        professional_level: _this.professional_level //职级
      }).then(function (res) {
        _this13.$notify({
          message: '提交成功',
          type: 'success'
        });
        _this13.$nextTick(function () {
          _this13.GetList();
        });
        _this13.dialogFormVisible = false;
      });
    },
    // 新增
    createData: function createData() {
      var _this14 = this;
      this.$refs.editForm.validate(function (valid) {
        if (valid) {
          _this14.$confirm('确认提交吗？', '提示', {}).then(function () {
            var end = [_this14.editForm.structure_id].map(function (e) {
              return e[e.length - 1];
            }).toString();
            var _this = _this14.editForm;
            AddUserList({
              username: _this.username,
              realname: _this.realname,
              flower_name: _this.flower_name,
              //花名
              mobile: _this.mobile,
              group_id: _this.group_id,
              //所属组id（角色id）
              structure_id: end,
              //部门id
              hiredate: _this.hiredate,
              //入职日期
              positive_date: _this.positive_date,
              //转正日期
              positive_status: _this.positive_status,
              //转正状态
              password: _this.password,
              individual_sales: _this14.is_leader !== '暂无' ? _this.individual_sales * 10000 : 0,
              group_sales: _this14.is_leader == 1 ? _this.group_sales * 10000 : 0,
              email: _this.email,
              professional_level: _this.professional_level //职级
            }).then(function (res) {
              _this14.$notify({
                message: '提交成功',
                type: 'success'
              });
              _this14.$refs['editForm'].resetFields();
              _this14.dialogFormVisible = false;
              _this14.GetList();
            }).catch(function () {});
          }).catch(function () {
            // 打印一下错误
          });
        }
      });
    },
    //选中操作
    selsChange: function selsChange(val) {
      this.deltableoptions = val;
      this.editForm = Object.assign({}, this.deltableoptions[0]);
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }
    },
    // 删除
    handleDel: function handleDel() {
      var _this15 = this;
      if (this.deltableoptions.length !== 0) {
        this.$confirm('确认删除该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            id: _this15.tempId.toString()
          };
          DelUserList(para).then(function (res) {
            _this15.$notify({
              message: '删除成功',
              type: 'success'
            });
            _this15.$nextTick(function () {
              //获取列表方法
              _this15.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中删除项');
      }
    },
    //冻结
    FrozenClick: function FrozenClick() {
      var _this16 = this;
      if (this.deltableoptions.length == 1) {
        this.$confirm('确认冻结该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          _this16.deltableoptions.forEach(function (item, index) {
            var para = {
              id: item.id
            };
            if (item.status == 0) {
              _this16.$message('此项已冻结');
            } else {
              Frozen(para).then(function (res) {
                _this16.$notify({
                  message: '冻结成功',
                  type: 'success'
                });
                _this16.$nextTick(function () {
                  //获取列表方法
                  _this16.GetList();
                });
              });
            }
          });
        }).catch(function () {});
      } else {
        this.$message('请选中一个解冻项');
      }
    },
    //解冻
    TharClick: function TharClick() {
      var _this17 = this;
      if (this.deltableoptions.length == 1) {
        this.$confirm('确认解冻该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          _this17.deltableoptions.forEach(function (item, index) {
            var para = {
              id: item.id
            };
            if (item.status == 1) {
              _this17.$message('此项已经是解冻状态。');
            } else {
              Thar(para).then(function (res) {
                _this17.$notify({
                  message: '解冻成功',
                  type: 'success'
                });
                _this17.$nextTick(function () {
                  //获取列表方法
                  _this17.GetList();
                });
              });
            }
          });
        }).catch(function () {});
      } else {
        this.$message('请选中一个解冻项');
      }
    },
    // 获取职级列表
    getLevelListFun: function getLevelListFun() {
      var _this18 = this;
      getProfessionalLevelList().then(function (res) {
        _this18.levelOptions = res.data;
        // console.log('resssss', res);
      });
    }
  }
};