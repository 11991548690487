import request from '@/utils/request';
//zml获取用户列表（分页）
export function GetUserList(data) {
  return request({
    url: '/clientserver.php/User/userList',
    method: 'post',
    data: data
  });
}
//zml修改用户信息
export function UpdataUserList(data) {
  return request({
    url: '/clientserver.php/User/updateUserInfo',
    method: 'post',
    data: data
  });
}
//ll
//zml修改用户密码
export function UpdataoneUserList(data) {
  return request({
    url: '/clientserver.php/User/restPwd',
    method: 'post',
    data: data
  });
}
//zml获取用户信息（传id获取对应id信息，不传获取当前登录用户信息）
export function GetoneUserList(data) {
  return request({
    url: '/clientserver.php/User/userInfo',
    method: 'post',
    data: data
  });
}
//zml删除用户
export function DelUserList(data) {
  return request({
    url: '/clientserver.php/User/delete',
    method: 'post',
    data: data
  });
}
//zml新增用户
export function AddUserList(data) {
  return request({
    url: '/clientserver.php/User/register',
    method: 'post',
    data: data
  });
}
//zml导出用户列表（通过浏览器直接下载，用postman看不到效果）
export function exportEmployeeExcel(data) {
  return request({
    url: '/clientserver.php/User/exportEmployeeExcel',
    method: 'post',
    data: data
  });
}
//zml冻结用户
export function Frozen(data) {
  return request({
    url: '/clientserver.php/User/frozen',
    method: 'post',
    data: data
  });
}
//zml解冻用户
export function Thar(data) {
  return request({
    url: '/clientserver.php/User/thar',
    method: 'post',
    data: data
  });
}
//zml查询所有角色信息
export function AuthGroup(data) {
  return request({
    url: '/clientserver.php/AuthGroup/index',
    method: 'post',
    data: data
  });
}
//zml部门信息
export function Structure() {
  return request({
    url: '/clientserver.php/Structure/index',
    method: 'post'
  });
}