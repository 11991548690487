var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { margin: "20px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { margin: "20px 0px", pading: "0px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 } },
                      [
                        _vm._v(
                          "\n                    部门名称:\n                    "
                        ),
                        _c("el-cascader", {
                          attrs: {
                            options: _vm.bumenoptions,
                            props: {
                              checkStrictly: true,
                              value: "id",
                              label: "name",
                            },
                            clearable: "",
                            size: "small",
                            filterable: "",
                            "expand-trigger": "hover",
                            placeholder: "请选择部门",
                          },
                          on: { change: _vm.handleChange },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 } },
                      [
                        _vm._v(
                          "\n                    角色名称:\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            attrs: {
                              size: "small",
                              clearable: "",
                              placeholder: "请选择角色",
                            },
                            on: { change: _vm.jiaosehandleChange },
                            model: {
                              value: _vm.jiaose,
                              callback: function ($$v) {
                                _vm.jiaose = $$v
                              },
                              expression: "jiaose",
                            },
                          },
                          _vm._l(_vm.jiaoseoptions, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { float: "right" },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 6, xl: 4 },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            type: "daterange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "timestamp",
                            size: "small",
                            "default-time": ["00:00:00", "23:59:59"],
                          },
                          on: { change: _vm.DataTime },
                          model: {
                            value: _vm.value1,
                            callback: function ($$v) {
                              _vm.value1 = $$v
                            },
                            expression: "value1",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { margin: "20px 0px", "min-width": "50%" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["新建"],
                                    expression: "['新建']",
                                  },
                                ],
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.handleAdd },
                              },
                              [_vm._v("新建")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["编辑"],
                                    expression: "['编辑']",
                                  },
                                ],
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.handleEdit },
                              },
                              [_vm._v(" 编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["删除"],
                                    expression: "['删除']",
                                  },
                                ],
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.handleDel },
                              },
                              [_vm._v("删除")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["冻结"],
                                    expression: "['冻结']",
                                  },
                                ],
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.FrozenClick },
                              },
                              [_vm._v("冻结")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["解冻"],
                                    expression: "['解冻']",
                                  },
                                ],
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.TharClick },
                              },
                              [_vm._v("解冻")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticStyle: { float: "right" },
                            attrs: { xs: 24, sm: 8, md: 8, lg: 6, xl: 4 },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select",
                                attrs: {
                                  placeholder: "请输入姓名,手机号查询客户",
                                  clearable: "",
                                  size: "small",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleFilter($event)
                                  },
                                },
                                model: {
                                  value: _vm.key_word,
                                  callback: function ($$v) {
                                    _vm.key_word = $$v
                                  },
                                  expression: "key_word",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.handleFilter },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      ref: "tb",
                      staticClass: "ble bte bre pct100",
                      staticStyle: { margin: "10px 0px" },
                      attrs: {
                        data: _vm.list,
                        fit: "",
                        width: "100%",
                        border: "",
                        "highlight-current-row": "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                      },
                      on: { "selection-change": _vm.selsChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", align: "center" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          width: "100px",
                          label: "人员编号",
                          type: "index",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3093164208
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "姓名",
                          align: "center",
                          prop: "realname",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "账号",
                          align: "center",
                          prop: "username",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "部门名称",
                          align: "center",
                          prop: "structure_name",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "角色",
                          align: "center",
                          prop: "group_title",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "手机",
                          align: "center",
                          prop: "mobile",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "入职时间",
                          align: "center",
                          prop: "hiredate",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "是否转正", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.positive_status == 1
                                          ? "是"
                                          : "否"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1102385147
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "转正时间",
                          align: "center",
                          prop: "positive_date",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "状态", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm._f("statusFilter")(
                                          row.status
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            +row.status == 1 ? "正常" : "冻结"
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3876151503
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                _vm._v(" "),
                _c("pagination", {
                  attrs: {
                    "current-page": _vm.query.page,
                    totalPage: _vm.query.total,
                    pageSize: _vm.query.pagesize,
                  },
                  on: {
                    handleSizeChange: _vm.handleSizeChange,
                    handleCurrentChange: _vm.handleCurrentChange,
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.textMap[_vm.dialogStatus],
                      visible: _vm.dialogFormVisible,
                      "close-on-click-modal": false,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogFormVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "editForm",
                        staticStyle: { width: "500px", "margin-left": "50px" },
                        attrs: {
                          model: _vm.editForm,
                          "label-width": "140px",
                          rules: _vm.editFormRules,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "部门", prop: "structure_id" } },
                          [
                            _vm.editForm.type === 1
                              ? _c("el-input", {
                                  attrs: { disabled: true },
                                  model: {
                                    value: _vm.editForm.structure_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editForm,
                                        "structure_id",
                                        $$v
                                      )
                                    },
                                    expression: "editForm.structure_id",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-cascader", {
                              ref: "cascader",
                              attrs: {
                                filterable: "",
                                placeholder: "请选择部门",
                                options: _vm.bumenoptions,
                                props: { value: "id", label: "name" },
                                clearable: "",
                              },
                              model: {
                                value: _vm.editForm.structure_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "structure_id", $$v)
                                },
                                expression: "editForm.structure_id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "角色", prop: "group_id" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.editForm.group_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "group_id", $$v)
                                  },
                                  expression: "editForm.group_id",
                                },
                              },
                              _vm._l(_vm.jiaoseoptions, function (item, index) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: index },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "姓名", prop: "realname" } },
                          [
                            _c("el-input", {
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "请输入真实姓名",
                              },
                              model: {
                                value: _vm.editForm.realname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "realname", $$v)
                                },
                                expression: "editForm.realname",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "用户名", prop: "username" } },
                          [
                            _c("el-input", {
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "请输入6-10字母和数字",
                              },
                              model: {
                                value: _vm.editForm.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "username", $$v)
                                },
                                expression: "editForm.username",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "密码", prop: "password" } },
                          [
                            _c("el-input", {
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "默认为zklm123456",
                              },
                              model: {
                                value: _vm.editForm.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "password", $$v)
                                },
                                expression: "editForm.password",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "花名" } },
                          [
                            _c("el-input", {
                              attrs: { "auto-complete": "off" },
                              model: {
                                value: _vm.editForm.flower_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "flower_name", $$v)
                                },
                                expression: "editForm.flower_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机", prop: "mobile" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.editForm.mobile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "mobile", $$v)
                                },
                                expression: "editForm.mobile",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "人员职级", prop: "mobile" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.level },
                                model: {
                                  value: _vm.editForm.professional_level,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editForm,
                                      "professional_level",
                                      $$v
                                    )
                                  },
                                  expression: "editForm.professional_level",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "暂无", value: "" },
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.levelOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.professional_level,
                                    attrs: {
                                      label: item.professional_level,
                                      value: item.professional_level,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.is_leader !== "暂无"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "individual_sales",
                                  label: "个人业绩指标",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "200px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { autocomplete: "off" },
                                  model: {
                                    value: _vm.editForm.individual_sales,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editForm,
                                        "individual_sales",
                                        $$v
                                      )
                                    },
                                    expression: "editForm.individual_sales",
                                  },
                                }),
                                _vm._v("万元\n                    "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.is_leader == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "group_sales",
                                  label: "团队业绩指标",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "200px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { autocomplete: "off" },
                                  model: {
                                    value: _vm.editForm.group_sales,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editForm, "group_sales", $$v)
                                    },
                                    expression: "editForm.group_sales",
                                  },
                                }),
                                _vm._v("万元\n                    "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "邮箱" } },
                          [
                            _c("el-input", {
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "",
                              },
                              model: {
                                value: _vm.editForm.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "email", $$v)
                                },
                                expression: "editForm.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "入职时间", prop: "hiredate" },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.editForm.hiredate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editForm, "hiredate", $$v)
                                    },
                                    expression: "editForm.hiredate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "是否转正",
                                  prop: "positive_status",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.editForm.positive_status,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "positive_status",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.positive_status",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("已转正"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("未转正"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.editForm.positive_status == 1
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "is-required",
                                    attrs: {
                                      label: "转正时间",
                                      prop: "positive_date",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.editForm.positive_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "positive_date",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.positive_date",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            nativeOn: {
                              click: function ($event) {
                                _vm.dialogFormVisible = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _vm.dialogStatus == "create"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.createData },
                              },
                              [_vm._v("添加")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.prohibit,
                                },
                                on: { click: _vm.updateData },
                              },
                              [_vm._v("修改")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }